/* COLOR PALETTE */
/*
Dark blue: #0000ff
Dark green: #1e5a00
Bright pink: #c800c8
Light blue: #c8dcdc
Light green: #b9cb91
*/


/* CSS overrides */

body {
  color: black;
}

h1, h2, h3, h4, h5, h6 {
  font-family:  "Hoefler Text", Georgia, Times, Serif;
  font-size: 32px;
  letter-spacing: 0.1px;
  line-height: 1.1em;
  text-transform: none;
  color: black !important;
}

p {
  font-family: Helvetica, Arial, Sans-serif;
  font-size: 20px;
  line-height: 1.7em;
}

strong, b {
  color: black !important;
}

#page-wrapper {
  background-image: -moz-linear-gradient(top, rgba(255,255,255,0.0), rgba(255,255,255,0.0)), -moz-linear-gradient(top, rgba(255,255,255,0.0), rgba(255,255,255,0.0)), url("../../images/flowers.jpg");
  background-image: -webkit-linear-gradient(top, rgba(255,255,255,0.1), rgba(255,255,255,0.0)), -webkit-linear-gradient(top, rgba(255,255,255,0.0), rgba(255,255,255,0.0)), url("../../images/flowers.jpg");
  background-image: -ms-linear-gradient(top, rgba(0,0,0,0.0), rgba(0,0,0,0.0)), -ms-linear-gradient(top, rgba(0,0,0,0.75), rgba(0,0,0,0.75)), url("../../images/flowers.jpg");
  background-image: linear-gradient(top, rgba(0,0,0,0.0), rgba(0,0,0,0.0)), linear-gradient(top, rgba(0,0,0,0.0), rgba(0,0,0,0.0)), url("../../images/flowers.jpg");
  background-size: cover;
  background-position: center;
}

.wrapper a {
  border-bottom: 2px solid #c800c8;
  color: black !important;
  text-decoration: none;
}

.wrapper a:visited {
  color: black;
  text-decoration: none;
}

.wrapper a:hover {
  color: #c800c8 !important;
  background-color: transparent !important;
}

input[type="radio"] + label {
  font-size: 20px;
}

li {
  font-size: 20px;
}

ul.checklist li:before {
    color: black;
}

.button:focus {
    outline: 1px dotted #555555;
}

#banner {
  background-color: #324440;
  color: #9fc7bf;
  background-image: -moz-linear-gradient(top, rgba(255,255,255,0.0), rgba(255,255,255,0.0)), -moz-linear-gradient(top, rgba(255,255,255,0.0), rgba(255,255,255,0.0)), url("../../images/banner1.jpg");
  background-image: -webkit-linear-gradient(top, rgba(255,255,255,0.1), rgba(255,255,255,0.0)), -webkit-linear-gradient(top, rgba(255,255,255,0.0), rgba(255,255,255,0.0)), url("../../images/banner1.jpg");
  background-image: -ms-linear-gradient(top, rgba(0,0,0,0.0), rgba(0,0,0,0.0)), -ms-linear-gradient(top, rgba(0,0,0,0.75), rgba(0,0,0,0.75)), url("../../images/banner1.jpg");
  background-image: linear-gradient(top, rgba(0,0,0,0.0), rgba(0,0,0,0.0)), linear-gradient(top, rgba(0,0,0,0.0), rgba(0,0,0,0.0)), url("../../images/banner1.jpg");
  background-attachment: fixed, fixed, fixed;
  background-position: center 100%, center 100%, center 100%;
  background-repeat: repeat, repeat, no-repeat;
  background-size: cover, cover, cover;
  height: 30vh;
  min-height: 30em;
  overflow: hidden;
  position: relative;
  text-align: center;
  text-transform: uppercase;
}

#banner:after {
  background-color: #000000;
  opacity: 0.1;
}

#banner-flowers .inner {
  margin: 10em;
}

#tides-banner {
  max-width: 50vw;
  margin: 0 0 5em 0;
}

#tides-banner img {
  max-height: 100%;
  max-width: 100%;
}

#banner .button {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #000000;
  color: #000000 !important;
}

#header.alt {
  /* color: #000000; */
}

#header {
  background-color: #c800c8eb;
}

#header.alt :hover {
  color: #666666;
}

#header.alt2 {
  color: #dddddd;
  background-color: rgba(0,0,0,.8);
}

#nav {
  background-color: #0000ff;
  font-family: Helvetica, Arial, Sans-serif;
  font-size: 20px;
  width: 16em;
}

#main > header {
  background-position: center 86%, center 100%, center 8.4px !important;
  color: #000000;
}

header h1 {
  font-family: Helvetica, Arial, Sans-serif;
  font-size: 18px;
  color: white !important;
}

#main > header h2 {
  color: #000000;
}

#projectDescription {
  font-size: 1.5em;
}

#uiSpeakDisplay {
  min-height: 10em;
}

.wrapper.style1 {
    background-color: #1e5a00;
}

.wrapper.style1 a {
  color: white;
}

.wrapper.style2 input[type="submit"], .wrapper.style2 input[type="reset"], .wrapper.style2 input[type="button"], .wrapper.style2 button, .wrapper.style2 .button {
    background-color: black;
    /* box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 1.0); */
    box-shadow: none;
}

.wrapper.style2 #startRecordButton {
  background-color: #dc3545;
}

.wrapper.style2 h2 {
  color: white !important;
}

.wrapper.style2 #uploadButton {
  background-color: #007bff;
  font-size: 0.9rem;
}

.wrapper.style3 {
  background-color: #b9cb91;
  color: black;
}

.wrapper.style4 {
  background-color: #c8dcdc;
  color: black;
}

.wrapper.style4 h3, .wrapper.style4 strong {
  color: black;
}

.wrapper.style4 h2, .wrapper.style4 h3, .wrapper.style4 h4 {
  color: black;
}

.wrapper.style4 h4 {
  color: black;
  margin-left: 4rem;
  font-size: 24px;
}

.wrapper.style6 {
  background-color: #EDE2A9;
  color: black;
}

.wrapper.style6 h3 {
  color: black;
}

.wrapper.style7 {
  background-color: #c8dcdc;
  color: black !important;
}

.wrapper.style7 h2, .wrapper.style7 a {
  color: black;
}

#footer {
  padding: 0em 0;
  /* padding: 2em; */
  background-color: #1e5a00;
}

#footer .copyright {
  /* text-align: left; */
  margin: 0;
}

#supportLogos > .image {
  display: block;
}

.supporter {
  /* margin: 1em 0; */
}

.supporterText {
  text-align: center;
}

/* SPEAK TAGS */

#uiSpeakDisplay form {
  display: block;
  margin: 1em;
}

#speakMap {
  height: 400px;
  width: 100%;
}

#pac-input {
    margin: 10px;
    border: 1px solid transparent;
    border-radius: 2px 0 0 2px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    height: 32px;
    outline: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    color: black !important;
    width: 75%;
    background-color: white;
}

#emptywavebox {
  border: 1px black solid;
  height: 150px;
  width: 100%;
  margin: 1em 0 0 0;
  background-color: grey;
}

#wavebox {
  border: 1px white solid;
  height: 150px;
  width: 100%;
  margin: 1em 0 1em 0;
}

#waveform {
  height: 129px;
  width: 100%;
}

#waveform-timeline {
  margin-bottom: 1em;
}

#inputmeter {
  border: 1px black solid;
  background-color: #ffffff;
  height: 150px;
  width: 100%;
}

#safariinputmeter {
  border: 1px black solid;
  background-color: #ffffff;
  height: 150px;
  width: 100%;
}

#countdown {
  font-size: 3em;
  margin: .5em 0;
}

/* LISTEN STYLES */

#listenMap {
  height: 800px;
  width: 100%;
}

#uiListenDisplay form {
  display: block;
  margin: 1em;
}

.main > .inner.full {
  padding: 0;
}

#main h2 {
  letter-spacing: 0.2rem;
}

.inner {
  padding-bottom: 10px;
}

.region {
  font-weight: bold;
  font-size: 2em;
  padding-bottom: .3em;
}

.community {
  padding-bottom: .1em;
}

.question {
  font-style: italic;
  padding-bottom: .3em;
}

.infoTagText {
  margin: 0 0 5px 0;
}

.infoDateText {
  margin: 0 0 5px 0;
}

#infoVoteBlock {
  margin: 10px 0px;
}

.infoFlag {
  margin: 10px 10px 0 0;
}

.infoLike {
  margin: 10px 10px 0 0;
}

.infoDownload {
  margin: 10px 10px 0 10px;
  color: #5d5d5d;
}

#infoShareBlock {
  margin: 10px 0px;
}

/* ALERTIFY STYLES */

.alertify {
  z-index: 10 !important;
}
.alertify .alert .msg, .alertify .dialog .msg {
  color: black;
}

.alertify .alert nav button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button),
.alertify .dialog nav button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button) {
  background: gray !important;
  margin: 6px 0px !important;
}

.alertify-notifier {
    text-transform: capitalize;
}

#footerElement {
  padding: 0 24px 24px 24px;

}

#footerElement .ajs-ok {
  width: 100%;
  margin: 0;
}

.ajs-dialog, .ajs-footer {
  background-color: #C6B092DD !important;
}
.ajs-header {
  background-color: transparent !important;
  font-family: "Hoefler Text", Georgia, Times, Serif;
  font-size: 1.2em;
}

.ajs-content {
  padding: 0 0 24px 0 !important;
}

/* #alertStartButton {
    width: 100%;
} */

/* SHARING STYLES */

#tagBlock li {
  font-size: 2em;
  line-height: 1.6;
  list-style-type: none;
  padding-left: 0em;
}

#soundBlock {
  margin-bottom: 1em;
}

#pictureBlock img {
  width: 100%;
}

#mapBlock {
  width: auto;
  margin: 0;
  padding: 0;
  height: 600px;
  position: relative;
}

#shareBlock {
  margin: 1.5em 0;
}

#contributeButton, #listenMoreButton {
  background-color: black;
  width: 100%;
  color: white !important;
  margin: 0;
}

#contributeButton:hover, #listenMoreButton:hover {
  opacity: 0.85;
}

.btn {
  background-color: black;
  width: 100%;
  color: white !important;
  margin: 0;
}

.btn:hover {
  opacity: 0.85;
  background-color: black;
}

/* from Mike S Demo */

#mixMap {
  top: 0%;
  height: 800px;
  width: 100%;
}

#mapbox {
  /* top: 0%; */
  height: 800px;
  width: 100%;
}

#errorDisplay {
  background-color: #F00;
  color: black;
  margin: 1em 5%;
  padding: 1em;
  font-weight: bold;
  font-size: x-large;
  display: none;
}

#tagSelection {
  position: absolute;
  background-color: white;
  opacity : 0.8;
  bottom: 10%;
  left: 5%;
  width: 25%;
  z-index: 5000;
  padding: 1%;
}

#tagSelection h2 {
  margin-top: 0.25em;;
}

#tagSelection label {
  display: block;
  margin-bottom: 0.5em;
}

#transportBtn {
  display: none;
  margin: 2em auto;
  width: 30vw;
}

#transportContainer .button {
  width: 100%;
  padding: 0 1em;
  font-size: 0.7em;
}

.flex-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  padding: 0;
  margin: 0;
  list-style: none;
}

.flex-item {
  /* background: tomato; */
  padding: 5px;
  width: 25%;
  /* height: 150px; */
  /* margin-top: 10px; */
  /* line-height: 150px; */
  /* color: white; */
  /* font-weight: bold; */
  /* font-size: 3em; */
  text-align: center;
}

#listenTransportContainer .flex-item {
  flex: 1 1 0px;
}

#listenContainer {
  /* position: absolute; */
  height: 95vh;
  width: 100%;
  top: 0%;
}

#listenTransportContainer {
  position: relative;
  bottom: 4em;
  width: 50vw;
  height: 4em;
  margin: auto;
}

#listenTransportContainer .button,
#footerElement button {
  margin: -6em auto 0 auto;
  padding: 0 1em;
  background: #ffffffe0;
  box-shadow: inset 0 0 0 3px rgba(0, 0, 255, 1);
  color: blue !important;
  width: 100%;
}

/* MAPBOX STYLES */

.marker {
  background-image: url('https://www.google.com/intl/en_us/mapfiles/ms/micons/green-dot.png');
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.mapboxgl-popup {
  max-width: 200px;
}

.mapboxgl-popup-content {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}

.mapboxgl-popup-content p {
  line-height: 1.5em;
}


.mapboxgl-popup-close-button {
  display: none;
}

@media screen and (max-width: 1280px) {


}

@media screen and (max-width: 980px) {

  #tides-banner {
    max-width: 60vw;
  }

  #banner {
    min-height: 20em;
    background-position-y: top;
  }

  .flex-item {
    width: 50%;
  }

}

@media screen and (max-width: 736px) {

  #tides-banner {
    max-width: 80vw;
  }

  #banner {
    min-height: 20em;
    background-position-y: top;
  }

  input[type="radio"] + label {
    font-size: 1.2em;
  }

  #uiSpeakDisplay form {
    margin: 1em 1em 1em 0;
  }

  #listenTransportContainer {
    width: 90vw;
  }

}

@media screen and (max-width: 480px) {

  #tides-banner {
    max-width: 80vw;
  }

  #banner {
    min-height: 14em;
    background-position-y: top;
  }

  button, .button {
    width: 100%;
    margin: .3em 0;
  }

  #speakMap {
    margin: 1em 0 1em -2em;
    width: 100vw !important;
  }

  #speak {
    padding-top: 4em;
  }

  .flex-item {
    width: 100%;
  }

  #infoVoteBlock {
    margin: 15px 0px;
  }

  #infoVoteBlock span {
    padding: 15px;
  }

  .alertify .ajs-dialog {
    margin: 20% auto !important;
  }

  .alertify .ajs-button {
    margin: 4px 0 !important;
  }

  .mainBtn {
    width: auto !important;
  }

}


/* OSM */

#banner {
  background-color: #324440;
  color: #9fc7bf;
  background-image: -moz-linear-gradient(top, rgba(255,255,255,0.4), rgba(255,255,255,0.4)), -moz-linear-gradient(top, rgba(255,255,255,0.4), rgba(255,255,255,0.4)), url("../../images/manzanar-geologic-map.jpg");
  background-image: -webkit-linear-gradient(top, rgba(255,255,255,0.4), rgba(255,255,255,0.4)), -webkit-linear-gradient(top, rgba(255,255,255,0.4), rgba(255,255,255,0.4)), url("../../images/manzanar-geologic-map.jpg");
  background-image: -ms-linear-gradient(top, rgba(0,0,0,0.25), rgba(0,0,0,0.35)), -ms-linear-gradient(top, rgba(0,0,0,0.75), rgba(0,0,0,0.75)), url("../../images/manzanar-geologic-map.jpg");
  background-image: linear-gradient(top, rgba(0,0,0,0.25), rgba(0,0,0,0.35)), linear-gradient(top, rgba(0,0,0,0.75), rgba(0,0,0,0.75)), url("../../images/manzanar-geologic-map.jpg");
  background-attachment: fixed, fixed, fixed;
  background-position: center 100%, center 100%, center 100%;
  background-repeat: repeat, repeat, no-repeat;
  background-size: cover, cover, cover;
  height: 50vh;
  min-height: 40em;
  overflow: hidden;
  position: relative;
  text-align: center;
  text-transform: uppercase;
}

#banner-flowers {
  background-color: #324440;
  color: #9fc7bf;
  background-image: -moz-linear-gradient(top, rgba(255,255,255,0.4), rgba(255,255,255,0.4)), -moz-linear-gradient(top, rgba(255,255,255,0.4), rgba(255,255,255,0.4)), url("../../images/flowers.jpg");
  background-image: -webkit-linear-gradient(top, rgba(255,255,255,0.4), rgba(255,255,255,0.4)), -webkit-linear-gradient(top, rgba(255,255,255,0.4), rgba(255,255,255,0.4)), url("../../images/flowers.jpg");
  background-image: -ms-linear-gradient(top, rgba(0,0,0,0.25), rgba(0,0,0,0.35)), -ms-linear-gradient(top, rgba(0,0,0,0.75), rgba(0,0,0,0.75)), url("../../images/flowers.jpg");
  background-image: linear-gradient(top, rgba(0,0,0,0.25), rgba(0,0,0,0.35)), linear-gradient(top, rgba(0,0,0,0.75), rgba(0,0,0,0.75)), url("../../images/flowers.jpg");
  background-attachment: fixed, fixed, fixed;
  background-position: center 100%, center 100%, center 100%;
  background-repeat: repeat, repeat, no-repeat;
  background-size: cover, cover, cover;
  height: 50vh;
  min-height: 40em;
  overflow: hidden;
  position: relative;
  text-align: center;
  text-transform: uppercase;
}

#banner-flowers .inner {
  margin: 10em;
}

#osm-banner {
  height: 35vh;
  margin: 0 0 5em 0;
  width: 60em;
}

#osm-banner img {
  max-height: 100%;
  max-width: 100%;
}

#osm-banner-short {
  height: 25vh;
  margin: 0 0 3em 0;
  width: 60em;
}

#osm-banner-short img {
  max-height: 100%;
  max-width: 100%;
}

#banner .button {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #000000;
  color: #000000 !important;
}

.mainBtn {
  font-family: "Hoefler Text", Georgia, Times, Serif;
  text-transform: none;
  box-shadow: none !important;
  font-size: 24px;
  border-bottom: 3px solid #c800c8 !important;
  padding: 0;
  height: 2em;
  line-height: 2em;
  color: black !important;
}

.mainBtn:hover {
  background-color: transparent;
}

#header.alt, #header.alt h1 {
  color: #000000 !important;
}

#header.alt :hover {
  color: #666666;
}

#header.alt2 {
  color: #dddddd;
  background-color: rgba(0,0,0,.8);
}

#main > header {
  background-image: url(../../images/flowers.jpg);
  background-position: center 86%, center 100%, center 8.4px !important;
  color: #000000;
}

#main > header h2 {
  color: #000000;
}

header h2 + p, header h3 + p, header h4 + p, header h5 + p, header h6 + p {
  font-size: 20px;
  /* margin-top: -0.6em; */
}

#uiSpeakDisplay {
  min-height: 10em;
}


.iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
  margin-bottom: 2em;
}
.iframe-container iframe {
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* SPEAK TAGS */

#uiSpeakDisplay form {
  display: block;
  margin: 1em;
}

#speakMap {
  height: 400px;
  width: 100%;
  margin-bottom: 2em;
}

#pac-input {
    margin: 10px;
    border: 1px solid transparent;
    border-radius: 2px 0 0 2px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    height: 32px;
    outline: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    color: black !important;
    width: 75%;
    background-color: white;
}

#waveform {
  border: 1px white solid;
  height: 150px;
  width: 100%;
  margin: 1em 0 0 0;
}

#waveform-timeline {
  margin-bottom: 1em;
}

#inputmeter {
  border: 1px black solid;
  background-color: #ffffff;
  height: 150px;
  width: 100%;
}

#countdown {
  font-size: 3em;
  margin: .5em 0;
}

/* LISTEN STYLES */

#listenMap {
  height: 800px;
  width: 100%;
}

#uiListenDisplay form {
  display: block;
  margin: 1em;
}

.main > .inner.full {
  padding: 0;
}

#main h2 {
  letter-spacing: 0.2rem;
}

.inner {
  padding-bottom: 10px;
}

.region {
  font-weight: bold;
  font-size: 2em;
  padding-bottom: .3em;
}

.community {
  padding-bottom: .1em;
}

.question {
  font-style: italic;
  padding-bottom: .3em;
}

/* ALERTIFY STYLES */

.alertify {
  z-index: 10 !important;
}
.alertify .alert .msg, .alertify .dialog .msg {
  color: black;
}

.alertify .alert nav button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button),
.alertify .dialog nav button:not(.btn):not(.pure-button):not(.md-button):not(.mdl-button) {
  background: gray !important;
}

/* SHARING STYLES */

#tag-block li {
  font-size: 2em;
  line-height: 1.6;
  list-style-type: none;
  padding-left: 0em;
}

#sound-block {
  margin-bottom: 1em;
}

#picture-block img {
  width: 100%;
}

#map-block {
  width: auto;
  margin: 0;
  padding: 0;
  height: 600px;
  position: relative;
}

/* from Mike S Demo */

#mixMap {
  top: 0%;
  height: 800px;
  width: 100%;
}

#mapbox {
  /* top: 0%; */
  height: 100%;
  width: 100%;
}

#errorDisplay {
  background-color: #F00;
  color: black;
  margin: 1em 5%;
  padding: 1em;
  font-weight: bold;
  font-size: x-large;
  display: none;
}

#tagSelection {
  position: absolute;
  background-color: white;
  opacity : 0.8;
  bottom: 10%;
  left: 5%;
  width: 25%%;
  z-index: 5000;
  padding: 1%;
}

#tagSelection h2 {
  margin-top: 0.25em;;
}

#tagSelection label {
  display: block;
  margin-bottom: 0.5em;
}

#playPauseBtn {
  /* display: none; */
  /* margin: 2em auto; */
  /* width: 30vw; */
}

#playPauseBtn:active {
  background-color: #cccccc;
}

#skipButton:active {
  background-color: #cccccc;
}

#transportBtn {
  display: none;
  margin: 2em auto;
  width: 30vw;
}

.fa-play:before {
  margin: 0 10px;
}

/* MAPBOX STYLES */

.marker {
  background-image: url('https://www.google.com/intl/en_us/mapfiles/ms/micons/green-dot.png');
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.mapboxgl-popup {
  max-width: 200px;
}

.mapboxgl-popup-content {
  text-align: left;
  font-family: 'Open Sans', sans-serif;
  background-color: #C6B092DD !important;
  padding: 2em !important;
}

.mapboxgl-popup-content p {
  margin: 0;
}

.mapboxgl-popup-content h3 {
  margin-bottom: 0.5em;
}

.mapboxgl-popup-tip {
  border-top-color: #C6B092DD !important;
  border-bottom-color: #C6B092DD !important;
}

@media screen and (max-width: 480px) {

  #banner {
    min-height: 0;
  }

  #osm-banner {
    height: 25vh;
    margin: 0 0 1em 0;
    width: 60em;
  }

}
